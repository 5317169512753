@import url("https://fonts.googleapis.com/css?family=Ariel:400,300,700");
// Override default variables before the import
// $body-bg: #000;

$font-family-sans-serif: 'Ariel', sans-serif !important;
$primary: #1D9E68;

.menu-flags-button {
    border: none !important;
}

.btn:disabled {
    opacity: 0.25 !important;
}

.btn-primary {
    color: 'white';
}

.modal-content {
    background: none !important
}

/*
.modal-backdrop.show {
    opacity: 0 !important;
}
*/

.btn-outline-secondary:hover {
    color: #6c757d !important;
}

.modal-fullscreen {
    max-width: 500px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';